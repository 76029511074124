<template>
    <div class="rb-view">
        <div>
            <el-card shadow="hover" size="small">
                <div class="date-time">
                    <div style="white-space: nowrap">
                        <!-- 上周 -->
                        <div class="nav-btn" style="margin-right: 24px;" @click="nextDay(-7)">	&lt;&lt;</div>
                        <!-- 上日 -->
                        <div class="nav-btn" style="margin-right: 24px;" @click="nextDay(-1)">	&lt;</div>
                    </div>
                    <div class="date-time-list">
                        <template v-for="d in date_line_option" v-bind:key="d">
<!--                            <div class="date-time-item data-time-item-active">-->
                            <div :class="['date-time-item',d.date === search_option.date ? 'data-time-item-active':'']" @click="navToDate(d.date)">
                                <div class="item-date ">{{d.date}}</div>
                                <div class="item-week">{{d.weekday}}</div>
                            </div>
                        </template>
                    </div>
                    <div style="white-space: nowrap">
                        <!-- 下周 -->
                        <div class="nav-btn" style="margin-left: 24px" @click="nextDay(7)">&gt;&gt;</div>
                        <!-- 下日 -->
                        <div class="nav-btn" style="margin-left: 24px" @click="nextDay(1)">&gt;</div>
                    </div>
                </div>
                <div class="line"></div>
                <!--title begin-->
                <div class="area-title-list">
                    <text style="text-align: left;width: 80px;font-size: 12px">开始时间</text>
<!--                    <text class="area-title">1号运动场</text>-->
<!--                    <text class="area-title">2号运动场</text>-->
<!--                    <text class="area-title">3号运动场</text>-->
<!--                    <text class="area-title">4号运动场</text>-->
<!--                    <text class="area-title">5号运动场</text>-->
<!--                    <text class="area-title">6号运动场</text>-->
<!--                    <text class="area-title">7号运动场</text>-->
                    <text class="area-title" v-for="item in playground_list" v-bind:key="item.id">{{item.title}}</text>
                </div>
                <!--title end-->
                <div class="area-list-content">
                    <template v-for="row in grid_data.time_line" v-bind:key="row">
                    <div class="area-title-list">
                        <text style="text-align: left;width: 80px;font-size: 14px;font-weight: bold">{{row?row.time_tip:''}}</text>
<!--                        <template v-for="(cdx,cell) in row.schedule_list" v-bind:key="cell.id">-->
                        <template v-for="(cell,cdx) in row.schedule_list" v-bind:key="cell">
                            <template v-if="cell">
<!--                                <div class="area area-over">-->
                                <div :class="['area',ENUM_PLAY_TYPE_CLASS_MAP[cell.play_type],cell.status == 4?'area-over':'']" @click="onEditTap(cell)" @contextmenu.prevent="onRemoveContextMenu(cell)">
                                    <div class="area-bg">
                                        <div class="area-name">{{cell.playground_title}}</div>
                                        <div class="area-info">
                                            <div class="area-type">{{(params_map['play_type'] && params_map['play_type'][cell.play_type])?params_map['play_type'][cell.play_type].value:''}}</div>
                                            <div class="area-price">¥{{cell.price}}</div>
                                        </div>
                                    </div>
                                    <div class="area-info area-info-bg">
                                        <div class="area-type">{{(params_map['play_schedule_status'] && params_map['play_schedule_status'][cell.status])?params_map['play_schedule_status'][cell.status].value:''}}</div>
                                        <div class="area-price">
                                          <text>{{cell.people_total}}</text>
                                          <text> / </text>
                                          <text v-if="cell.play_type == 2">1</text>
                                          <text v-else>{{cell.people_limit_up}}</text>
                                         </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="area area-over" @click="onCreateTap(row.begin_at,playground_list[cdx].id)">
<!--                                    {{row.begin_at}},{{playground_list[cdx].id}}-->
                                </div>
                            </template>
                        </template>
                    </div>
                    </template>
                </div>

            </el-card>

        </div>
        <el-dialog v-model="dialogFormVisible" :title="dialogFormTitle" @close="onTellDialogClose" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <play-schedule-detail :action="dialogFormAction" :row-id="dialogFormRowId"  :begin-at="dialogFormBeginAt" :playground-id="dialogFormPlaygroundId" @onTellDialogClose="onTellDialogClose"></play-schedule-detail>
        </el-dialog>

    </div>
</template>


<script>
    import PlayScheduleDetail from './play-schedule-detail'
    import {getGrid, remove} from "@/api/hado/play-schedule";
    import {getList as getPlaygroundList} from "@/api/hado/playground";
    import {getParams} from "@/api/hado/param";
    import {formatTimestamp, formatDate, getWeek} from "@/utils/time";
    const param_list = ['playground_type','play_schedule_status','play_type']
    const PLAY_TYPE_CLASS_MAP = Object.assign({
        0:'area-none', //无
        1:'area-single', //散场
        2:'area-team', //包场
        3:'area-coach', //教练场 进阶课程
        4:'area-experience', //体验试玩
    })
    export default {
        name: "play-schedule-table",
        components:{PlayScheduleDetail},
        data(){
            return{
                dialogFormTitle:'',
                dialogFormVisible:false,
                dialogFormRowId:0,
                dialogFormAction:'',//create view edit
                dialogFormPlaygroundId:'',
                dialogFormBeginAt:0,

                loading:false,
                search_form_name:'playground_search',
                page_option:{page:1,size:10,total:0},
                search_option:{date:''},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},
                grid_data:{},
                date_line_option:[],

                ENUM_PLAY_TYPE_CLASS_MAP:PLAY_TYPE_CLASS_MAP,
                playground_list:[],
            }
        },
        created(){
            // this.initDateLine()
            this.search_option.date = formatDate(new Date())
            // this.search_option.date = '2021-11-16'
            this.rePaintDateLine()
            this.initPage()
        },
        methods:{
            initPage(){
                this.initParams().then(()=>{
                    this.getDateGrid(this.search_option.date)
                })
            },
            reloadPage() {
                this.getDateGrid(this.search_option.date)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getDateGrid(data){
                getGrid(data).then(resp=>{
                    this.grid_data = resp.data
                    getPlaygroundList().then(pl=>{
                        this.playground_list = pl.data.records
                    })
                })
            },
            onViewTap(row) {
                console.log('onViewTap:', row)
                this.dialogFormRowId = row.id
                this.dialogFormAction = 'view'
                this.dialogFormVisible = true
            },
            onEditTap(row) {
                console.log('onEditTap:', row)
                this.dialogFormAction = 'edit'
                this.dialogFormRowId = row.id
                this.dialogFormVisible = true
            },
            onCreateTap(begin_at,playground_id) {
                console.log('onCreateTap:',begin_at,playground_id)
                this.dialogFormRowId = 0;
                this.dialogFormAction = 'add'
                this.dialogFormPlaygroundId = playground_id
                this.dialogFormBeginAt = begin_at
                this.dialogFormVisible = true
            },
            onRemoveTap(row) {
                this.$messageBox.confirm('确定删除当前数据?', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    remove(row.id).finally(() => {
                        this.reloadPage()
                    })
                })
            },
            onTellDialogClose() {
                this.dialogFormVisible = false;
                this.dialogFormRowId = 0;
                this.reloadPage()
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            // changeScheduleDate(date){
            //
            // }
            //初始化日期
            // initDateLine(){
            //     // let time_now = new Date()
            //     // let dl = []
            //     // for(let i=0;i<7;i++){
            //     //     let tn = time_now.getTime() + 3600*24*1000*i
            //     //     let t_m_d = formatDate(new Date(tn))
            //     //     let fd = formatDate(new Date(tn))
            //     //     console.log('t_m_d:',t_m_d)
            //     //     console.log('fd:',fd)
            //     //
            //     //     let opt = {}
            //     //     opt.date_tip = t_m_d
            //     //     opt.weekday = getWeek(fd).replace('周','')
            //     //     opt.date = fd
            //     //     console.log('opt:',opt)
            //     //
            //     //     dl.push(opt)
            //     // }
            //     // this.date_line_option = dl
            // },
            nextDate(day_offset){
              let date_str = formatDate(new Date( new Date(this.search_option.date).getTime()+3600*24*1000*Number(day_offset)))
              this.navToDate(date_str)
            },
            navToDate(date_str){
                this.search_option.date = date_str
                this.getDateGrid(this.search_option.date)
                this.rePaintDateLine()
            },
            nextDay(day_offset){
                this.nextDate(day_offset)
            },
            // nextWeek(day_offset){
            //     this.nextDate(day_offset)
            // },
            rePaintDateLine(){
                //判断头部是否要切换新的日期表

                let date_target = new Date(this.search_option.date)

                //判断是对应所在星期的第几天 然后开始算前后时间
                // date_target.getDay()

                let left_count = date_target.getDay()
                let right_count = 6-left_count

                let dl = []

                for(let i=0;i<=left_count;i++){ //正常日历显示
                // for(let i=1;i<left_count;i++){ //周一开始显示
                    let tn = date_target.getTime() - 3600*24*1000*(left_count-i)
                    let t_m_d = formatDate(new Date(tn))
                    let fd = formatDate(new Date(tn))
                    let opt = {}
                    opt.date_tip = t_m_d
                    opt.weekday = getWeek(fd).replace('周','')
                    opt.date = fd
                    console.log('opt:',opt)
                    dl.push(opt)
                }

                for(let i=1;i<=right_count;i++){//正常日历显示
                // for(let i=0;i<=right_count;i++){ //周一开始显示
                    let tn = date_target.getTime() + 3600*24*1000*i
                    let t_m_d = formatDate(new Date(tn))
                    let fd = formatDate(new Date(tn))
                    let opt = {}
                    opt.date_tip = t_m_d
                    opt.weekday = getWeek(fd).replace('周','')
                    opt.date = fd
                    console.log('opt:',opt)
                    dl.push(opt)
                }
                this.date_line_option = dl

            },

            // 监听右键删除
            onRemoveContextMenu(row){
              console.log("点击了右键执行删除方法")

              this.onRemoveTap(row)
            }

        }
    }
</script>

<style scoped>
    .date-time {display: flex;justify-content: space-between;align-items: center;}
    .nav-btn {width: 32px;height: 32px;display: flex;justify-content: center;align-items: center;color: #409eff}
    .date-time-list{display: flex;flex: 1;justify-content: space-between;}
    .date-time-item{display: flex;align-items: center;justify-content: center;flex-direction: column;padding:12px 24px;cursor: pointer;position:relative;}
    .date-time-item:before{position:absolute; content:'';left:0;right:0;width:100%;height:100%; border:1px solid transparent;}
    .date-time-item:hover:before{ border:1px solid #24a5ff;}
    .data-time-item-active{background: #24a5ff;border-radius: 16px;color: #fff;transform:scale(1.31);border:0px;}
    .data-time-item-active:hover:before{border:0px;}
    .item-date{font-size: 12px;}
    .item-week{font-size: 14px;margin-top: 8px}
    .line{margin: 16px;height: 1px;background: #f5f5f5}
    .area-title-list{display: flex;justify-content: space-between;padding:0 12px;align-items: center}
    .area-title,.area{flex: 1;text-align: center;font-size: 14px;font-weight: bold}
    .area{margin:8px;border-radius: 16px;min-height: 84px;height:84px;max-height: 84px;cursor: pointer;}
    .area-name{font-size: 14px;font-weight: bold;text-align: left}
    .area-info{display: flex;font-size: 12px;justify-content: space-between;align-items: center}
    .area-bg{padding: 8px;border-radius: 16px 16px 0 0;color: #fff}
    .area-info-bg{background: #ffffff;border-radius:0 0 16px 16px;padding: 8px;}
    .area-list-content{margin-top: 24px}

    /deep/ .el-card {
        border-radius: 8px;
        margin-bottom: 16px;
        border: 1px solid #fff;
    }
    /*不同状态*/
    /*包场*/
    .area-team{background: #24a5ff;border: 4px solid #24a5ff;}
    /*拼场*/
    .area-single{background: #9e66ff;border: 4px solid #9e66ff;}
    /*试玩*/
    .area-experience{background: #ff631f;border: 4px solid #ff631f;}
    /*教练*/
    .area-coach{background: #3edb49;border: 4px solid #3edb49;}
    /*结束*/
    .area-over{background: #888888;border: 4px solid #888888;opacity: 0.5}
    /*无放置*/
    .area-none{background: #333333;border: 4px solid #333333;}
</style>


